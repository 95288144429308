<template>
	<div>
		<div class="content">
			<!-- 个人信息 -->
			<div class="mb20 pageTitle"><b>个人信息</b></div>
			<div>
				<el-form class="form" label-width="120px" v-loading="loading">
					<el-form-item label="账号:">
						<span>{{dataObj.username}}</span>
					</el-form-item>
					<el-form-item label="姓名:">
						<span>{{dataObj.name}}</span>
					</el-form-item>
					<el-form-item label="联系电话:">
						<el-input type="text" placeholder="请输入联系电话" v-model="dataObj.telephone" clearable></el-input>
					</el-form-item>
					<el-form-item label="Skype:">
						<div class="flexX">
							<el-input placeholder="请输入Skype" v-model="dataObj.skype" clearable></el-input>
						</div>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">确定</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				dataObj:{}
			};
		},
		created() {
			this.info();
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					let val = Math.floor(((value * 1) * 100).toPrecision(12))
					return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			info() {
				this.$api.info({}).then(res => {
					if (res.status == 1) {
						this.dataObj = res.data
					}
				})
			},
			onSubmit(){
				if (isEmpty(this.dataObj.telephone, "请输入电话号码")) {
					return
				}
				if (!isMobile(this.dataObj.telephone, "输入的电话号码不合法")) {
					return
				}
				let data = {
					phone: this.dataObj.telephone,
					skype: this.dataObj.skype
				}
				this.loading = true
				this.$api.setPhone(data).then(res=>{
					this.loading = false
					if(res.status == 1){
						this.info()
						this.$message({
							type: 'success',
							message: res.msg
						})
					}
				}).catch(error=>{
					this.loading = false
				})
			}
		},
		watch: {},
	};
</script>

<style lang="scss">
	@import '@/style/variables.scss';

	.content {
		.pageTitle {
			background-color: $navSubColor;
			padding: 10px;
		}

		.tips {
			width: 460px;
		}

		.form {
			width: 460px;
		}
	}
</style>
